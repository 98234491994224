<template>
    <div class="d-flex align-items-center">
        <div class="flex-grow-1 mr-1">
            <b-form-input
                type="number"
                v-model.number="first"
                @focus="whenBlurGno()"
                @blur="whenBlurGno()"
                min="0"
                max="4"
                step="0.01"
                v-check-min-max-value
            ></b-form-input>
        </div>
        <div class="flex-grow-1">
            <b-form-input
                type="number"
                v-model.number="end"
                @focus="whenBlurGno()"
                @blur="whenBlurGno()"
                min="0"
                max="4"
                step="0.01"
                v-check-min-max-value
            ></b-form-input>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        gno_range_start:{
            default:null
        },
        gno_range_end: {
            default: null
        }
    },
    methods:{
        whenBlurGno(){
            let firstGno = this.first;
            let endGno = this.end;
            if(firstGno == null  || endGno == null || firstGno ==='' || endGno === '') return
            if(firstGno > endGno){
                this.first=endGno
            }
            else{
                this.end=endGno;
            }
        }
    },
    computed:{
        first:{
            get(){
                return this.gno_range_start
            },
            set(val){
                this.$emit("update:gno_range_start",val)
            }
        },
        end:{
            get(){
                return this.gno_range_end
            },
            set(val){
                this.$emit("update:gno_range_end",val)
            }
        }
    }
}
</script>
